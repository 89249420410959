import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/lib/interface';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { arraySort, momentizeObjectDates } from '@core/utils/methods';
import { JURISDICTION_DATES_FOR_MOMENTIZE } from '@models/constants';
import {
  JurisdictionDetailsSummary,
  JurisdictionDetailsZoning,
  JurisdictionZoningData,
} from '@models/interfaces';
import { EditablePage, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import {
  CountySelectors,
  PermittingSelectors,
  UserSelectors,
} from '@store/selectors';
import { JurisdictionSummarySection } from '../../components';
import { JURISDICTION_CAN_USER_EDIT } from '../../models';
import { JurisdictionZoningSection } from './components';
import { getDefaultZoningData } from './helpers';
import { ZoningProps } from './models';

import './styles.scss';

const { useForm } = Form;

const Zoning: FC<ZoningProps> = ({
  isEditing,
  permissions,
  toggleEditing,
  history,
}: PropsWithChildren<ZoningProps>) => {
  const dispatch = useDispatch();

  const [summaryForm] = useForm();

  const { pathname } = useLocation();
  const searchParams = new URLSearchParams();

  const states = useSelector(CountySelectors.getStatesWithCounties);

  const [resetZoning, setResetZoning] = useState<boolean>(false);

  const {
    zoning,
    summary,
    id: jurisdictionId,
  } = useSelector(
    PermittingSelectors.getJurisdictionDetailsData(['summary', 'zoning', 'id']),
  );

  const [data, setData] = useState<JurisdictionZoningData>(() =>
    getDefaultZoningData(summary, zoning),
  );
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const isDisabled = ObjectComparatorService.objectsCompare(
    getDefaultZoningData(summary, zoning),
    data,
  );

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDisabled));
  }, [isDisabled]);

  useDidUpdateEffect(() => {
    setData(getDefaultZoningData(summary, zoning));
  }, [zoning]);

  const updateSummarySection = (summaryData: Store): void => {
    setData((prevState: JurisdictionZoningData) => ({
      ...prevState,
      summarySection: {
        ...prevState.summarySection,
        ...summaryData,
      },
    }));
  };

  const updateZoningSection = (id: string, formValue: Store): void => {
    setData((prevState: JurisdictionZoningData) => ({
      ...prevState,
      zoningSection: prevState.zoningSection.map(
        (zoningSection: JurisdictionDetailsZoning) => {
          const condition = zoningSection.id !== id;

          return condition
            ? zoningSection
            : {
                ...zoningSection,
                ...formValue,
              };
        },
      ),
    }));
  };

  const onSubmit = async (): Promise<void> => {
    try {
      const zoningSorted = arraySort([...zoning!], 'ASC', 'id');

      setSubmitDisabled(true);

      const zoningDataSorted = arraySort(
        data.zoningSection.map((section: JurisdictionDetailsZoning) =>
          momentizeObjectDates(section, JURISDICTION_DATES_FOR_MOMENTIZE, true),
        ),
        'ASC',
        'id',
      );

      const needUpdatedZoning = zoningDataSorted.filter(
        (z: JurisdictionDetailsZoning, index: number) =>
          !ObjectComparatorService.objectsCompare(z, zoningSorted[index]),
      );

      const summaryFormValues = summaryForm.getFieldsValue();

      const stateName = states.find(item => {
        return item.id === summaryFormValues.state;
      })?.name;

      await HttpService.getHttpRequests(
        PermittingHttpService,
      ).updateJurisdictionZoning(jurisdictionId!, {
        summarySection: ObjectDifferencesService.getObjectsDiff(
          summary || {},
          summaryFormValues,
          ['jurisdictionName'],
        ) as Omit<JurisdictionDetailsSummary, 'id'>,
        zoningSection: needUpdatedZoning,
      });

      toggleEditing?.();

      searchParams.set('tabName', 'Zoning');

      history!.replace(
        pathname.replace(
          /(\/jurisdiction\/)([^/]+)\/([^/]+)(.*)/,
          `$1${encodeURIComponent(
            summaryFormValues.jurisdictionName,
          )}/${stateName}$4`,
        ),
      );
      NotificationsLoader.notificationSuccess('Information has been updated!');
    } catch (e) {
      console.error(e);
    }

    setSubmitDisabled(false);
  };

  const onCancel = (): void => {
    summaryForm.resetFields();
    setResetZoning(!resetZoning);
    setData(getDefaultZoningData(summary, zoning));
    toggleEditing?.();
  };

  const buttonsDisabled = submitDisabled || isDisabled;

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-jurisdiction-zoning">
      <div
        className={classNames('page-wrap', {
          'page-wrap_with-actions': isEditing,
        })}
      >
        <JurisdictionSummarySection
          permissions={permissions!.zoningSummaryFields}
          isEditing={isEditing}
          updateSummarySection={updateSummarySection}
          jurisdictionId={jurisdictionId!}
          data={summary!}
          form={summaryForm}
        />
        <JurisdictionZoningSection
          isEditing={isEditing}
          zoningLTEFields={permissions!.zoningLTEFields}
          zoningNSBFields={permissions!.zoningNSBFields}
          updateZoningSection={updateZoningSection}
          data={zoning!}
          reset={resetZoning}
          jurisdictionId={jurisdictionId!}
        />
      </div>
      {isEditing && (
        <section className="actions">
          {JURISDICTION_CAN_USER_EDIT() && (
            <PrimaryButton
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
          )}
          <PrimaryButton
            title="Cancel"
            disabled={buttonsDisabled}
            type="default"
            onClick={onCancel}
          />
        </section>
      )}
    </main>
  );
};

export const JurisdictionZoning: FC<RouteComponentProps> = ({ history }) => {
  const permissions = useSelector(
    UserSelectors.getPermittingJurisdictionPermissions,
  );

  return (
    <EditablePage
      editableComponent={Zoning}
      permissions={permissions}
      canUserEdit={JURISDICTION_CAN_USER_EDIT()}
      history={history}
    />
  );
};

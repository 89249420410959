import React, { FC, ReactText, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  BACKEND_DATE_FORMAT,
  ScopingAdditionFilterType,
} from '@symfa-inc/providence-types';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { HttpService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  isValidForm,
  momentizeObjectDates,
  removeEmptyFields,
} from '@core/utils/methods';
import { FORECASTED_DATE } from '@core/utils/validators';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import { RangePickerValues, ScopingBrowseFilter } from '@models/interfaces';
import {
  DateWorkerNull,
  MainScopingBrowseFilter,
  SwitchType,
} from '@models/types';
import { AdditionalFilters, NotificationsLoader } from '@shared/components';
import { DatePicker, PrimaryButton, RangePicker } from '@shared/modules';
import { BrowsePage } from '@shared/modules/browse-page';
import { ScopingSelectors } from '@store/selectors';
import {
  ADDITIONAL_FILTERS,
  SCOPING_BROWSE_COLUMNS,
  SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const ScopingBrowse: FC<RouteComponentProps> = () => {
  const [form] = useForm();
  const [scheduledForm] = useForm();
  const [exportForm] = useForm();
  const scopingService = HttpService.getHttpRequests(ScopingHttpService);

  const scopingBrowse = useSelector(ScopingSelectors.getScopingBrowseData);

  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [scheduleDate, setScheduleDate] = useState<DateWorkerNull>(null);
  const [exportDate, setExportDate] = useState<RangePickerValues>({
    from: null,
    to: null,
  });
  const [filterValue, setFilterValue] = useState<MainScopingBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
    showUnscheduledScopingDateRecords: false,
  });
  const [configureFilter, setConfigureFilter] = useState<ScopingBrowseFilter>(
    SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER,
  );

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows),
  };

  useDidUpdateEffect(() => {
    scopingService.getScopingBrowse(removeEmptyFields({ ...filterValue }));
  }, [filterValue]);

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  };

  const onChangeConfigureFilter = (filter: ScopingBrowseFilter): void =>
    setConfigureFilter((prevState: ScopingBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      showUnscheduledScopingDateRecords: value as SwitchType,
    }));

  const onSubmitDateScheduleDate = async (): Promise<void> => {
    const date = scheduleDate?.format(BACKEND_DATE_FORMAT);

    if (date !== undefined && (await isValidForm(scheduledForm))) {
      await scopingService.updateScopingBrowse(
        { ...removeEmptyFields(filterValue), projectIds: selectedRows },
        { date },
      );

      setScheduleDate(null);
      setSelectedRows([]);
      scheduledForm.resetFields();
    }
  };

  const onSubmitExport = async (): Promise<void> => {
    const notification = new NotificationsLoader({
      message: 'Loading',
      description: 'Please wait',
    });

    try {
      await scopingService.exportScheduledScoping(
        momentizeObjectDates(
          exportDate,
          ['from', 'to'],
          true,
        ) as RangePickerValues,
      );
      exportForm.resetFields();
      setExportDate({ to: null, from: null });
    } catch (e) {
      console.error(e);
    }
    notification.close();
  };

  const onChangeAdditionFilter = (filter: string | string[]): void => {
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      additionFilter: filter as ScopingAdditionFilterType,
    }));
  };

  return (
    <BrowsePage
      canOpenInNewTab
      columns={SCOPING_BROWSE_COLUMNS}
      data={scopingBrowse?.items}
      switchInfo="Show Only Unscheduled Scoping Projects"
      filters={
        <Filters
          formProps={{ form }}
          value={configureFilter}
          onChanges={onChangeConfigureFilter}
        />
      }
      tableProps={{
        rowSelection,
        pagination: {
          total: scopingBrowse?.total,
        },
        module: 'scoping',
      }}
      className="scoping-browse"
      place={tableCount(true)}
      filterFormProps={{ labelCol: { span: 8 }, form }}
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        filterValue,
        setConfigureFilter,
      )}
      cancelButtonProps={{
        onClick: (): void => {
          form.resetFields();
          setConfigureFilter(SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER);
        },
      }}
      onChange={browseFiltersChange(setFilterValue)}
      onSearchChanges={onSearchChanges}
      onApplyFilter={onApplyConfigureFilter}
      onSwitch={onOnlyCompletedSwitch}
      additional={
        <>
          <Row className="scoping-browse__schedule-form">
            <Col>
              <Form
                onValuesChange={(value: Store): void =>
                  setExportDate((prevState: RangePickerValues) => ({
                    ...prevState,
                    ...value.scheduledScopingDate,
                  }))
                }
                form={exportForm}
                autoComplete="off"
              >
                <RangePicker label="" id="scheduledScopingDate" />
                <PrimaryButton
                  onClick={onSubmitExport}
                  title="Download"
                  className="scoping-browse__btn"
                  disabled={!exportDate.from || !exportDate.to}
                />
              </Form>
            </Col>

            <Col>
              <Form
                onValuesChange={(value: Store): void =>
                  setScheduleDate(value.date)
                }
                form={scheduledForm}
              >
                <div className="scoping-browse__form-label">
                  <DatePicker
                    id="date"
                    label="Schedule Scoping for Selected"
                    formItemProps={{
                      rules: FORECASTED_DATE,
                    }}
                  />
                </div>

                <PrimaryButton
                  title="Submit"
                  className="scoping-browse__btn"
                  disabled={scheduleDate === null || !selectedRows.length}
                  onClick={onSubmitDateScheduleDate}
                />
              </Form>
            </Col>
          </Row>
          <Row className="scoping-browse__scoping-wrap">
            <AdditionalFilters
              filters={ADDITIONAL_FILTERS}
              onChange={onChangeAdditionFilter}
              activeFilters={(filterValue.additionFilter as string) || ''}
              mode="switch"
            />
          </Row>
        </>
      }
    />
  );
};

import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  ConfigureFilterProps,
  Filter,
  JurisdictionOption,
  OptionProps,
  PermittingBrowseFilter,
  RangePickerValues,
  StateWithCounties,
} from '@models/interfaces';
import { Autocomplete, RangePicker } from '@shared/modules';
import {
  CountySelectors,
  DDVSelectors,
  PermittingSelectors,
} from '@store/selectors';
import { PERMITTING_BROWSE_RANGE_FILTERS } from '../models';

export const PermittingBrowseFilters: FC<
  ConfigureFilterProps<PermittingBrowseFilter>
> = ({
  formProps,
  onChanges,
  value,
}: PropsWithChildren<ConfigureFilterProps<PermittingBrowseFilter>>) => {
  const [currentState, setState] = useState<StateWithCounties>();
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  const { ProjectTypeDDV, TowerOwnerDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes(['ProjectType', 'TowerOwner']),
  );
  const statesWithCounties = useSelector(CountySelectors.getStatesWithCounties);
  const jurisdictions = useSelector(PermittingSelectors.getJurisdictionList);
  const permittingUsers = useSelector(
    PermittingSelectors.getPermittingUsersOptions,
  );

  const onChooseState = (selectedState: SelectValue): void => {
    const state = statesWithCounties.find(
      (stateWithCounties: StateWithCounties) =>
        stateWithCounties.id === selectedState,
    );

    setState(state);

    setJurisdictionOptions(
      arrsToSelectableObject(
        jurisdictions.filter(
          (jurisdiction: JurisdictionOption) =>
            jurisdiction.state === state?.id,
        ),
        'id',
        'jurisdictionName',
      ),
    );
    onChanges({ state: state?.id, jurisdiction: undefined, county: undefined });
    formProps?.form.resetFields(['jurisdiction', 'county']);
  };

  useEffect(() => {
    if (value.state !== currentState?.id) {
      onChooseState(value.state as SelectValue);
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        id="projectTypeId"
        key="projectTypeId"
        label="Project Type"
        options={ProjectTypeDDV}
        elementProps={{
          allowClear: true,
          value: value.projectTypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectTypeId: e as string }),
        }}
      />
      {PERMITTING_BROWSE_RANGE_FILTERS.map(({ id, name }: Filter) => (
        <RangePicker
          id={id}
          key={id}
          label={name}
          formItemProps={{ className: 'aligned-form-item' }}
          value={value[id as keyof PermittingBrowseFilter] as RangePickerValues}
          onChange={(date?: RangePickerValues): void =>
            onChanges({ [id]: date })
          }
        />
      ))}
      <Autocomplete
        id="towerOwner"
        key="towerOwner"
        label="Tower Owner"
        options={TowerOwnerDDV}
        elementProps={{
          allowClear: true,
          value: value.towerOwner,
          onChange: (e: SelectValue): void =>
            onChanges({ towerOwner: e as string }),
        }}
      />
      <Autocomplete
        id="state"
        key="state"
        label="State"
        options={arrsToSelectableObject(statesWithCounties, 'id', 'name')}
        elementProps={{
          allowClear: true,
          value: value.state,
          onChange: onChooseState,
        }}
      />
      <Autocomplete
        id="county"
        key="county"
        label="County"
        elementProps={{
          allowClear: true,
          disabled: !currentState,
          value: value.county,
          onChange: (e: SelectValue): void =>
            onChanges({ county: e as string }),
        }}
        options={arrsToSelectableObject(
          currentState?.counties ?? [],
          'id',
          'name',
        )}
      />
      <Autocomplete
        id="jurisdiction"
        key="jurisdiction"
        label="Jurisdiction"
        options={jurisdictionOptions}
        elementProps={{
          allowClear: true,
          disabled: !currentState,
          value: value.jurisdiction,
          onChange: (e: SelectValue): void =>
            onChanges({ jurisdiction: e as string }),
        }}
      />
      <Autocomplete
        id="permittingAgent"
        key="permittingAgent"
        label="Permitting Agent"
        options={permittingUsers || []}
        elementProps={{
          allowClear: true,
          value: value.permittingAgent,
          onChange: (e: SelectValue): void =>
            onChanges({ permittingAgent: e as string }),
        }}
      />
    </>
  );
};
